import {
    FormControl,
    FormHelperText,
    InputLabel,
    MenuItem,
    Select as MuiSelect,
} from '@material-ui/core'
import { useField } from '@unform/core'
import { generateInputRandomName } from '@Utils/Mocks/generateInputRandomName'
import { generateRandomId } from '@Utils/Mocks/generateRandomId'
import { useEffect, useRef } from 'react'
import { SelectProps } from './Select.types'

export const Select = ({
    options,
    optionRenderProp = 'label',
    supportMessages,
    name = generateInputRandomName(),
    type,
    label,
    ...props
}: SelectProps) => {
    const inputRef = useRef(null)
    const { fieldName, defaultValue, registerField, error } = useField(name)
    const ID = `${name}-select-${generateRandomId()}`
    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef,
            getValue: (ref) => {
                return ref.current.value
            },
            setValue: (ref, value) => {
                ref.current.value = value
            },
            clearValue: (ref) => {
                ref.current.value = ''
            },
        })
    }, [fieldName, registerField])

    return (
        <FormControl
            required={props.required}
            variant="outlined"
            disabled={props.disabled}
        >
            <InputLabel error={Boolean(error)} id={ID}>
                {label}
            </InputLabel>
            <MuiSelect
                ref={inputRef}
                defaultValue={defaultValue}
                name={name}
                label={label}
                labelId={ID}
                variant="outlined"
                error={Boolean(error)}
                {...props}
            >
                {options?.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                        {option?.[optionRenderProp]}
                    </MenuItem>
                ))}
            </MuiSelect>
            {error && (
                <FormHelperText error>
                    {supportMessages?.[0]?.message || error}
                </FormHelperText>
            )}
        </FormControl>
    )
}
