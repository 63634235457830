import {
    ChangeProposalOwnerUseCase,
    CreateProposalUseCase,
    DeleteProposalsUseCase,
    GetProposalPipeUseCase,
    GetProposalUseCase,
    GetProposalsUseCase,
    UpdateProposalUseCase,
} from '@Commercial/Proposal/Domain/UseCases'
import { DeleteProductProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/DeleteProductProposal'
import { DuplicateProposalUseCase } from '@Commercial/Proposal/Domain/UseCases/DuplicateProposal'
import { GetVariablesUseCase } from '@Commercial/Proposal/Domain/UseCases/GetVariables'
import { UpdateTaxValueProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/UpdateTaxProposalValue'
import { UpdateVariablesProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/UpdateVariables'

export const Register = {
    UseCaseGetProposals: GetProposalsUseCase,
    UseCaseGetProposal: GetProposalUseCase,
    UseCaseCreateProposal: CreateProposalUseCase,
    UseCaseUpdateProposal: UpdateProposalUseCase,
    UseCaseDeleteProposal: DeleteProposalsUseCase,
    UseCaseDeleteProductProposal: DeleteProductProposalsUseCase,
    UseCaseUpdateVariablesProposal: UpdateVariablesProposalsUseCase,
    UseCaseGetVariablesProposal: GetVariablesUseCase,
    UseCaseGetProposalPipe: GetProposalPipeUseCase,
    UseCaseDuplicateProposal: DuplicateProposalUseCase,
    UseCaseUpdateTaxValueProposal: UpdateTaxValueProposalsUseCase,
    UseCaseChangeProposalOwner: ChangeProposalOwnerUseCase,
}
