import { Proposal } from '@Commercial/Proposal/entities/Proposal'
import { Api } from '@Services/api'
import ProposalDataSource from '../ProposalDataSource'
import { CommercialVariables, DBCommercialProposal } from './Entity/Proposal'

export class ProposalAPI implements ProposalDataSource {
    axios = new Api()

    async getProposalById(id: string): Promise<DBCommercialProposal> {
        const { data } = await this.axios.get(`/commercial/proposal/${id}`)
        return data
    }

    async duplicate(id: string): Promise<DBCommercialProposal> {
        const { data } = await this.axios.post(
            `/commercial/proposal/duplicate/${id}`
        )
        return data[0]
    }

    async getProposal(): Promise<DBCommercialProposal[]> {
        const { data } = await this.axios.get('/commercial/proposal')
        return data
    }

    async getVariables(): Promise<CommercialVariables[]> {
        const { data } = await this.axios.get('/commercial/proposal/variables')
        return data
    }

    async search(query: string): Promise<DBCommercialProposal[]> {
        const { data } = await this.axios.get(
            `/commercial/proposal/search/${query}`
        )
        return data
    }

    async createProposal(product: Proposal): Promise<DBCommercialProposal> {
        const { data } = await this.axios.post('/commercial/proposal', product)
        return data
    }

    async updateProposal(
        product: DBCommercialProposal
    ): Promise<DBCommercialProposal> {
        const { data } = await this.axios.put(
            `/commercial/proposal/${product._id}`,
            product
        )
        return data
    }

    async getPipeProposal(pipe: string) {
        const { data } = await this.axios.get(
            `/commercial/proposal/pipe/${pipe}`
        )
        return data
    }

    async updateVariables(variables: CommercialVariables) {
        const { data } = await this.axios.put(
            `/commercial/proposal/variables/${variables._id}`,
            variables
        )
        return data
    }

    async deleteProposal(id: string): Promise<void> {
        await this.axios.delete(`/commercial/proposal/${id}`)
    }

    async deleteProductProposal(id: string): Promise<void> {
        await this.axios.delete(`/commercial/proposal/product/${id}`)
    }

    async updateProposalTaxValues(user: string): Promise<void> {
        await fetch(`https://n8n.buzato.dev.br/webhook/4eb739ab-40b1-4537-a4ac-43eca212cd24/${user}`)
    }

    async changeOwner(proposalId: string, newOwnerId: string): Promise<DBCommercialProposal> {
        return await this.axios.patch(`/commercial/proposal/change-owner/${proposalId}`, { newOwnerId })
    }

    getSource(): string {
        return this.axios.getSource()
    }
}
