import {
    ChangeProposalOwnerUseCase,
    CreateProposalUseCase,
    DeleteProposalsUseCase,
    GetProposalPipeUseCase,
    GetProposalsUseCase,
    GetProposalUseCase,
} from '@Commercial/Proposal/Domain/UseCases'
import { DeleteProductProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/DeleteProductProposal'
import { DuplicateProposalUseCase } from '@Commercial/Proposal/Domain/UseCases/DuplicateProposal'
import { GetVariablesUseCase } from '@Commercial/Proposal/Domain/UseCases/GetVariables'
import { UpdateVariablesProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/UpdateVariables'
import { Container } from 'inversify'
import { UpdateProposalUseCase } from '../../../Domain/UseCases/UpdateProposal'
import { CommercialProposalRepository } from '../../Repository/ProposalRepository'
import { ProposalAPI } from '../API/ProposalAPI'
import { Register } from './ProposalRegister'
import { UpdateTaxValueProposalsUseCase } from '@Commercial/Proposal/Domain/UseCases/UpdateTaxProposalValue'

const container = new Container()
container
    .bind<GetProposalUseCase>(Register.UseCaseGetProposal)
    .toDynamicValue(
        () =>
            new GetProposalUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind<GetProposalsUseCase>(Register.UseCaseGetProposals)
    .toDynamicValue(
        () =>
            new GetProposalsUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )

container
    .bind<CreateProposalUseCase>(Register.UseCaseCreateProposal)
    .toDynamicValue(
        () =>
            new CreateProposalUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind<UpdateProposalUseCase>(Register.UseCaseUpdateProposal)
    .toDynamicValue(
        () =>
            new UpdateProposalUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind(Register.UseCaseDeleteProposal)
    .toDynamicValue(
        () =>
            new DeleteProposalsUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )

container
    .bind(Register.UseCaseDeleteProductProposal)
    .toDynamicValue(
        () =>
            new DeleteProductProposalsUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind(Register.UseCaseGetVariablesProposal)
    .toDynamicValue(
        () =>
            new GetVariablesUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind(Register.UseCaseUpdateVariablesProposal)
    .toDynamicValue(
        () =>
            new UpdateVariablesProposalsUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )

container
    .bind(Register.UseCaseGetProposalPipe)
    .toDynamicValue(
        () =>
            new GetProposalPipeUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )
container
    .bind(Register.UseCaseDuplicateProposal)
    .toDynamicValue(
        () =>
            new DuplicateProposalUseCase(
                new CommercialProposalRepository(new ProposalAPI())
            )
    )

container.bind(Register.UseCaseUpdateTaxValueProposal).toDynamicValue(
    () =>
        new UpdateTaxValueProposalsUseCase(
            new CommercialProposalRepository(new ProposalAPI())
        )
)

container.bind(Register.UseCaseChangeProposalOwner).toDynamicValue(
    () =>
        new ChangeProposalOwnerUseCase(
            new CommercialProposalRepository(new ProposalAPI())
        )
)
export default container
