import { CommercialProposalRepository } from '@Commercial/Proposal/Data/Repository/ProposalRepository';
import { DBCommercialProposal } from '../../Data/DataSource/API/Entity/Proposal';

export interface ChangeProposalOwner {
    execute(params: { proposalId: string; newOwnerId: string }): Promise<DBCommercialProposal>
    getSource(): string
}

export class ChangeProposalOwnerUseCase implements ChangeProposalOwner {
    constructor(private repository: CommercialProposalRepository) {}

    async execute(params: { proposalId: string; newOwnerId: string }): Promise<DBCommercialProposal> {
      console.log('ChangeProposalOwnerUseCase.execute', params)
        return this.repository.changeOwner(params.proposalId, params.newOwnerId)
    }

    getSource(): string {
        return this.repository.dataSource.getSource()
    }
}
