import { Button, ButtonVariant } from '@Core/Components'
import { Modal, ModalFooter, ModalSize } from '@Core/Components/Modal/Modal'
import { useEffect, useMemo, useRef, useState } from 'react'
import { CommercialProposalContainer, CommercialProposalRegister } from '../../Data/DataSource/Container'
import { UserContainer, UserRegister } from '@User/Data/DataSource/Container'
import { toast } from 'react-toastify'
import { Select } from '@Core/Components/Select/Select'
import { Form } from '@unform/web'
import { FormHandles } from '@unform/core'


interface Seller {
    label: string
    value: string
}

interface Props {
    isOpen: boolean
    setIsOpen: (value: boolean) => void
    proposalId: string
    currentOwnerId: string
    onSuccess: () => void
}

export const ChangeOwnerModal = ({ isOpen, setIsOpen, proposalId, currentOwnerId, onSuccess }: Props) => {
    const [sellers, setSellers] = useState<Seller[]>([])
    const formRef = useRef<FormHandles>(null)
    const [selectedSeller, setSelectedSeller] = useState('')
    const [loading, setLoading] = useState(false)

    const getSellersContainer = UserContainer.get(UserRegister.UseCaseGetUsers)

    const changeOwnerContainer = CommercialProposalContainer.get(
        CommercialProposalRegister.UseCaseChangeProposalOwner
    )

    const loadSellers = async () => {
        try {
            const response = await getSellersContainer.execute()
            const sellers = response.filter(seller => !seller?.firebase?.disabled).map(seller => ({
              label: seller.name || seller.mail,
              id: seller.firebase.uid,
              value: seller.mail
            }))
            setSellers(sellers)
        } catch (error) {
            console.error('Erro ao carregar vendedores:', error)
            toast.error('Erro ao carregar vendedores')
        }
    }

    const handleChange = async () => {
        try {
            setLoading(true)
            await changeOwnerContainer.execute({
                proposalId,
                newOwnerId: selectedSeller
            })
            toast.success('Vendedor alterado com sucesso!')
            onSuccess()
            setIsOpen(false)
        } catch (error) {
            console.error('Erro ao alterar vendedor:', error)
            toast.error('Erro ao alterar vendedor')
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (isOpen) {
            loadSellers()
            setSelectedSeller('')
        }
    }, [isOpen])
    const currentOwner = useMemo(() => {
        return sellers.find(seller =>{ 
            console.log(seller)
            return seller.id === currentOwnerId})
    }, [currentOwnerId, sellers])

    return (
        <Modal size={ModalSize.Small} isOpen={isOpen} onClose={() => setIsOpen(false)}>
            <Form ref={formRef} onSubmit={() => {}} className="flex flex-col gap-6 p-4">
                <h2 className="text-xl font-semibold text-gray-800">Transferir proposta</h2>
                <div className="bg-yellow-50 p-4 rounded-lg border border-yellow-200">
                    <p className="text-gray-700 mb-2">
                        Tem certeza que deseja transferir essa proposta para outro vendedor?
                    </p>
                    <p className="text-sm font-medium text-yellow-700">
                        ⚠️ Esta ação não pode ser desfeita
                    </p>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="font-medium text-gray-700">Vendedor atual</label>
                    <p className="text-gray-600">{currentOwner?.label || 'Não definido'}</p>
                </div>

                <div className="flex flex-col gap-2">
                    <label className="font-medium text-gray-700">Novo vendedor</label>
                    {sellers.length === 0 ? (
                        <p className="text-gray-500">Carregando...</p>
                    ) : (
                        <Select
                            label="Selecione o novo vendedor"
                            required
                            optionRenderProp="label"
                            options={sellers.filter(s => s.value !== currentOwnerId)}
                            onChange={(event) => setSelectedSeller(event.target.value)}
                            value={sellers.find(s => s.value === selectedSeller)}
                            className="w-full"
                            supportMessages={[
                                {
                                    type: 'required',
                                    message: 'Selecione um vendedor'
                                }
                            ]}
                        />
                    )}
                </div>

                <ModalFooter className="flex gap-3 justify-end pt-4 border-t">
                    <Button
                        onClick={() => setIsOpen(false)}
                        variant={ButtonVariant.secondary}
                    >
                        Cancelar
                    </Button>
                    <Button
                        onClick={handleChange}
                        variant={ButtonVariant.primary}
                        disabled={!selectedSeller || loading}
                    >
                        {loading ? 'Transferindo...' : 'Transferir'}
                    </Button>
                </ModalFooter>
            </Form>
        </Modal>
    )
}
